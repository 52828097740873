@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply fixed inset-0 w-full h-full bg-zinc-900 text-white;
}

#rootDiv {
  @apply overflow-hidden;
}

.tw-container {
  @apply relative max-w-screen-md w-full mx-auto;
}

.inputWrapper {
  @apply absolute bottom-0 left-0 right-0;
}

.inputWrapper > input {
  @apply block w-full bg-zinc-700 px-4 py-2 text-white placeholder-zinc-300;
}